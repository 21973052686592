<style src="../css/head.css"></style>

<style src="../css/detail.css"></style>

<template>
  <div>
    <Head-section></Head-section>

    <div id="bg_en"></div>
    <!-- <Foot-section></Foot-section> -->
  </div>
</template>
<script>
import HeadSection from "./HeadSection.vue";
// import FootSection from "./FootSection.vue";
export default {
  name: "Dreamtime",
  components: {
    HeadSection,
    // FootSection,
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "keywords",
          content: "Dream time",
        },
      ],
    };
  },
  data() {
    return {
      
    };
  },
};
</script>

<style>
#bg_en{
    background:url("../assets/background_en.jpg") no-repeat; 
    width: 100%;
    background-size: 100%; 
    min-height: 1396vw;
    }
</style>